<template>
  <div class="d-flex flex-column align-items-center body-container" v-if="!this.isLoading">
    <img class="mt-2 col-3" :src="require(`@/assets/game/logo-icon.png`)"/>
    <div class="d-flex flex-column align-items-center col-12">
      <div class="carousel-container col-12 mb-3">
        <div class="d-flex flex-column align-items-center img-container col-12">
          <img :src="require(`@/assets/howToPlay/htp-slide-one.png`)" class="col-11">
          <img class="col-1" :src="require(`@/assets/howToPlay/hpt-state-1.png`)">
        </div>
        <div class="d-flex flex-column align-items-center img-container col-12">
          <img :src="require(`@/assets/howToPlay/htp-slide-two.png`)" class="col-11">
          <img class="col-1" :src="require(`@/assets/howToPlay/hpt-state-2.png`)">
        </div>
        <div class="d-flex flex-column align-items-center img-container col-12">
          <img :src="require(`@/assets/howToPlay/htp-slide-three.png`)" class="col-11">
          <img class="col-1" :src="require(`@/assets/howToPlay/hpt-state-3.png`)">
        </div>
      </div>
      <button @click="$router.back()" class="col-12 mt-2 back-button">
        Back
      </button>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';

export default {
  name: "HowToPlay",
  computed: {
    ...mapState(['isLoading'])
  },
  methods: {
    ...mapMutations(['setIsLoading']),
  },
}
</script>

<style scoped>
.body-container {
  background-color: #e8e8e8;
  overflow-y: hidden;
  padding-bottom: 150px;
}

.carousel-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
}

.carousel-container::-webkit-scrollbar {
  display: none;
}

.img-container {
  display: flex;
  justify-content: center;
  position: relative;
}

.img-container:last-child {
  margin-right: 0;
}

.back-button {
  height: 50px;
  color: #FFFFFF;
  border: 0;
  background-color: #bfa668;
}

</style>
